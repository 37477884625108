<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <div class="d-inline-flex" style="width: 100%">
        <v-autocomplete
          :key="`${step.id}-pluginName`"
          v-model="properties.pluginName"
          outlined
          dense
          :items="plugins"
          :loading="isLoadingPlugins"
          :search-input.sync="searchRequestPlugins"
          clearable
          hide-no-data
          item-text="name"
          item-value="name"
          :label="$lang.labels.plugin"
          :placeholder="$lang.actions.startTyping"
          prepend-inner-icon="mdi-cloud-search-outline"
          :readonly="!canEdit"
          data-cy="plugin-plugins"
        ></v-autocomplete>
        <v-btn
          icon
          light
          color="primary"
          class="ml-1"
          data-cy="plugin-refresh-plugins"
          @click="searchPlugins(searchRequestPlugins)"
        >
          <v-icon
            dense
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1"
          color="primary"
          :disabled="!properties.pluginObj"
          data-cy="plugin-open-plugin"
          @click="openPlugin()"
        >
          {{ $lang.actions.openPlugin }}
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="properties.pluginObj ? properties.pluginObj.actions : []"
        :default-value="properties.action"
        step-label="plugin"
        :disabled="!properties.pluginObj"
        :label="!properties.pluginObj ? $lang.labels.selectPlugin : $lang.labels.action"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <div class="d-inline-flex" style="width: 100%">
        <v-autocomplete
          :key="`${step.id}-pluginSettingName`"
          v-model="properties.pluginSettingName"
          outlined
          dense
          :items="settings"
          :loading="isLoadingPluginSetting"
          :search-input.sync="searchRequestPluginSettings"
          clearable
          hide-no-data
          item-text="name"
          item-value="name"
          :label="$lang.labels.processSettingName"
          :placeholder="$lang.actions.startTyping"
          prepend-inner-icon="mdi-cloud-search-outline"
          :readonly="!canEdit"
          data-cy="pluginSettings-pluginSettingName"
        ></v-autocomplete>
        <v-btn
          icon
          light
          color="primary"
          class="ml-1"
          @click="searchPluginSettings(searchRequestPluginSettings)"
        >
          <v-icon
            dense
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
    </v-col>

    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="PLUGIN"
      :properties="properties"
      step-label="plugin"
      :readonly="!canEdit"
      :required="false"
      :credential-attr-name="'pluginCredentialName'"
      @change="handleChange('pluginCredentialName', $event)"
    />

    <v-col cols="12" class="pb-3">
      <add-key-value
        :key="`${step.id}-input`"
        type="input"
        :can-edit="canEdit"
        step-type="PLUGIN"
        :data="properties.input"
        :single-step="step"
        @dataChanged="handleChange('input', $event)"
      ></add-key-value>
    </v-col>

    <v-col cols="12" class="pb-3">
      <add-key-value
        :key="`${step.id}-output`"
        type="output"
        :can-edit="canEdit"
        step-type="PLUGIN"
        :data="properties.output"
        :single-step="step"
        @dataChanged="handleChange('output', $event)"
      ></add-key-value>
    </v-col>
  </v-row>
</template>

<script>
import {
  getPluginsUsingGET as getPlugins,
  getPluginByIdUsingGET as getPlugin,
  getSettingsUsingGET as getSettings
} from '@/utils/api'
import { mapActions } from 'vuex'
import { castValueForStep } from '@/utils/helpers'

import {
  ActionSelect,
  CredentialsSelect
} from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

export default {
  name: 'PluginStep',
  components: {
    ActionSelect,
    CredentialsSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      plugins: [],
      settings: [],
      searchRequestPlugins: '',
      searchRequestPluginSettings: '',
      isLoadingPlugins: false,
      isLoadingPluginSetting: false
    }
  },
  watch: {
    searchRequestPlugins: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchPlugins(val)
        }
      },
      immediate: true
    },
    searchRequestPluginSettings: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchPluginSettings(val)
        }
      },
      immediate: true
    }
  },
  created() {
    if (!this.properties.pluginObj) this.$set(this.properties, 'pluginObj', null)
    if (!this.properties.input) this.$set(this.properties, 'input', {})
    if (!this.properties.output) this.$set(this.properties, 'output', {})
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    handleChange(field, value) {
      // Typecasting for specific fields
      const castedValue = castValueForStep(
        field,
        value,
        { shouldBeString: ['pluginCredentialName'] }
      )

      this.$set(this.properties, field, castedValue)
      this.$emit('update-step', { field, castedValue })
    },
    searchPlugins(val = '') {
      this.isLoadingPlugins = true
      const obj = { permissionType: 'USE' }

      if (val && val.length > 1) obj.name = val

      getPlugins(obj)
        .then((res) => {
          this.plugins = res.data.data.items
          this.isLoadingPlugins = false

          const isSelected = res.data.data.items.find((x) => x.name === this.searchRequestPlugins)

          if (isSelected) {
            getPlugin({ id: isSelected.id })
              .then((res) => {
                const plugin = res.data.data

                if (plugin) this.properties.pluginObj = plugin
                if (plugin) this.properties.pluginName = plugin.name
                this.isLoadingPlugins = false
              })
              .catch((err) => {
                this.addSnackbar({
                  message: err,
                  timeout: 5000,
                  color: 'error'
                })
                this.isLoadingPlugins = false
              })
          }
        })
        .catch((err) => {
          this.isLoadingPlugins = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    searchPluginSettings(val = '') {
      this.isLoadingPluginSetting = true
      const obj = { type: 'PLUGIN' }

      if (val && val.length > 1) obj.name = val

      getSettings(obj)
        .then((res) => {
          this.settings = res.data.data.items
          this.isLoadingPluginSetting = false
        })
        .catch((error) => {
          this.isLoadingPluginSetting = false
          console.log(error)
        })
    },
    openPlugin() {
      if (this.properties.pluginObj)
        window.open(
          `/${localStorage.selectedLanguage || 'en'}/plugins/edit/${this.properties.pluginObj.id}`,
          '_blank'
        )
    }
  }
}
</script>
